import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ResendEamilAsync,
  loginAsync,
  verifyAdminAsync,
} from "../../redux/authSlice";
import { ForgetPasswordAsync } from "../../redux/adminManagementSlice";
import "./Login.scss";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import Horizontal from "../../assets/icons/Horizontal.svg";
import ShowEye from "../../assets/icons/show-eye.svg";
import HideEye from "../../assets/icons/Hide-eye-icon.svg";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyCode, setverifyCode] = useState("");
  const [page1, setPage1] = useState(1);
  const [AdminToken, setAdminToken] = useState("");
  const [iPAddress, setIPAddress] = useState("");
  const [notification, setNotification] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loginAccType, setLoginAccType] = useState("");
  const [loginAccUserID, setLoginAccUserID] = useState("");
  const [loginAccEmail, setLoginAccEmail] = useState("");
  const {
    loginLoading,
    sessionTokenAdmin,
    verifyAdminLoading,
    resendEamilLoading,
  } = useSelector((state) => state.auth);
  const { RegenerateOTPLoading } = useSelector((state) => state.generateOTP);
  const { forgetPasswordLoading, forgetPasswordError } = useSelector(
    (state) => state.adminManage
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onUserIdChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    getData();
    if (AdminToken?.length > 0) {
      setAdminToken(null);
    }
  }, []);
  const getData = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIPAddress(res.data.IPv4);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const validation = () => {
    if (!email) {
      toast.error("Please enter email and password");
      return;
      // } else if (!validator.isEmail(email)) {
      //   toast.error("Please enter a valid email");
      //   return;
    } else if (!password) {
      toast.error("Please enter email and password");
      return;
    }
    return true;
  };

  //previous signing method:0-------------
  // const signIn = (e) => {
  //   e.preventDefault();
  //   if (validation()) {
  //     const data = {
  //       userId: email.trim(""),
  //       password: password,
  //     };
  //     dispatch(loginAsync(data, false)).then((r) => {
  //       if (r?.accountType === "admin" && r?.isVerified === true) {
  //         moveToVerify();
  //         setAdminToken(sessionTokenAdmin);
  //       } else if (r?.accountType === "admin" && r?.isVerified === false) {
  //         setPage1(3);
  //       }
  //     });
  //   }
  // };
  const maskEmail = (email) => {
    if (!email) return "";
    const [name, domain] = email.split("@");
    // Mask the characters in the name, only show the first three characters
    const maskedName = name.slice(0, 3) + "****";
    return `${maskedName}@${domain}`;
  };
  const signIn = (e) => {
    e.preventDefault();
    if (validation()) {
      const data = {
        userId: email.trim(""),
        password: password,
      };
  console.log('datadatadatannnnnnn',data)
      var MaDetails = JSON.stringify(data);
      dispatch(loginAsync(data, false)).then((r) => {
        if (r?.success === true) {
          localStorage.setItem("MaUser", MaDetails);
          setLoginAccType(r?.user?.accountType);
          setLoginAccUserID(r?.user?.userId);
          const maskedEmail = maskEmail(r?.user?.emailAddress);
          setLoginAccEmail(maskedEmail);
          moveToVerify();
          setAdminToken(sessionTokenAdmin);
        }
      });
    }
  };

  const moveToVerify = () => {
    setPage1(2);
  };

  const verifyAdmin = (e) => {
    e.preventDefault();
    if (verifyAdminLoading || !verifyCode) {
      toast.error("Please enter OTP ");
      return;
    } else if (RegenerateOTPLoading) {
      console.log("wait,,,");
    } else {
      const data = {
        sessionToken: sessionTokenAdmin,
        otp: verifyCode,
        requestType: 0,
      };
      let data2 = {
        accountType: loginAccType,
        userId: loginAccUserID,
        otp: verifyCode,
      };
      dispatch(verifyAdminAsync(loginAccType === "admin" ? data : data2));
    }
  };
  const BackTOPrevPage = () => {
    navigate("/");
    setAdminToken(null);
  };
  const openGmail = () => {
    // Replace 'your_email@gmail.com' with your actual Gmail address
    const gmailUrl = "https://mail.google.com";
    window.open(gmailUrl, "_blank");
  };
  if (forgetPasswordLoading == true) {
    Swal.fire({
      didOpen: () => {
        Swal.showLoading();
      },
      title: "Sending forget password link to your linked email address",
      text: "Please Wait...",
      width: "550px",
    });
  } else {
    Swal.close();
  }
  const ForgetPassword = () => {
    dispatch(ForgetPasswordAsync(email)).then((r) => {
      if (r == true) {
        Swal.fire({
          icon: "success",
          title: "Forget link successfully sent on your email",
          text: "Please check your email",
          width: "550px",

          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            setEmail(null);
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Can't send an email at this moment ",
          text: forgetPasswordError,
          width: "500px",
        });
      }
    });
  };
  const ResendEmail = () => {
    let data = {
      accountType: "admin",
      userId: email,
    };
    dispatch(ResendEamilAsync(data)).then((r) => {
      if (r?.success === true) {
        setNotification(true);
      }
    });
  };
  return (
    <div className="login-page bg-slate-900 text-white  text-lg h-screen w-full flex justify-between items-center ">
      {loginLoading || verifyAdminLoading || resendEamilLoading ? (
        <Loader />
      ) : null}
      <div className="bg-[#38AB7C] text-lg h-screen  flex justify-center items-center flex-col w-0 sm:w-1/3">
        <div className="app-logo w-[100]">
          <img src={Horizontal} alt="ZAIBIDS" />
        </div>
        <div className="flex flex-col w-full h-full items-center justify-center">
          <div className="welcome-area">
            <h1 className="text-4xl md:text-5xl font-bold">Welcome Back</h1>
            {page1 == true ? (
              <h4 className="text-xl md:text-2xl font-semibold mt-3">
                Please login to continue
              </h4>
            ) : (
              <h4 className="text-xl md:text-2xl font-semibold mt-3">
                Please enter verification code for login
              </h4>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white text-black text-lg h-screen flex justify-center items-center sm:w-2/3  w-full flex-col ">
        <div className=" text-black sm:hidden w-20 mb-10">App Logo</div>

        <div className="login__container">
          {page1 === 1 ? (
            <>
              <h1 className="font-black signInText">SIGN IN</h1>
              <form>
                <h5>UserID</h5>
                <input
                  className="input-userID-auth"
                  type="text"
                  value={email ? email : ""}
                  onChange={onUserIdChange}
                  placeholder="UserID"
                />
                <h5>Password</h5>
                <div className="password-container-auth">
                  <input
                    type={visible ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                  <img
                    onClick={() => setVisible(!visible)}
                    className="show-password-icon"
                    src={visible ? ShowEye : HideEye}
                    alt="show"
                  />
                </div>
                {email ? (
                  <h5 onClick={ForgetPassword} className="ForgetPassword">
                    Forget password
                  </h5>
                ) : null}
                <button
                  type="submit"
                  className={`login__signInButton  ${
                    email && password
                      ? " hover:bg-[#158859] bg-[#38AB7C] text-white  font-bold"
                      : `bg-[grey] text-white  font-bold`
                  }`}
                  onClick={signIn}
                  disabled={!email || !password}
                >
                  SIGN IN
                </button>
              </form>
            </>
          ) : page1 === 2 ? (
            <>
              <h3 className="OTP_Heading">OTP Verification</h3>
              <div className="VerificationContainer">
                <div className="VerificationText">
                  We've sent a verification code on your registered email -{" "}
                  {loginAccEmail}
                </div>
              </div>
              <form>
                <input
                  type="text"
                  placeholder="Enter 5 digit verification code"
                  onChange={(e) => setverifyCode(e.target.value)}
                  value={verifyCode}
                  maxLength={5}
                  className="OTP_input"
                />

                <button
                  type="submit"
                  className="login__signInButton text-white bg-[#38AB7C] hover:bg-[#158859] font-bold"
                  onClick={verifyAdmin}
                  disabled={verifyAdminLoading || !verifyCode}
                >
                  VERIFY
                </button>
                <button className="BackToLoginPage " onClick={BackTOPrevPage}>
                  Back
                </button>
              </form>
            </>
          ) : (
            <div className="notificationCard">
              <p className="notificationHeading">Login Failed</p>
              <p className="notificationPara">
                Your account is not verified , Please check your email to
                verify.{" "}
              </p>
              <div className="buttonContainer">
                <button onClick={openGmail} className="AllowBtn">
                  Go to email
                </button>
                <button onClick={() => setPage1(1)} className="NotnowBtn">
                  Back
                </button>
              </div>
              {!notification ? (
                <span onClick={ResendEmail} className="ResendLink">
                  Resend link
                </span>
              ) : (
                <span className="ResendLink">Link send successfully</span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
