import React, { useEffect, useState, useRef } from "react";
import "./SearchBusinessAccount.scss";
import Container from "../../../components/HOC/Container";
import Vector from "../../../assets/icons/Vector.svg";
import Group_345 from "../../../assets/icons/Group_345.svg";
import SearchIcon from "@mui/icons-material/Search";
import keyboard_arrow_down from "../../../assets/icons/keyboard_arrow_down.svg";
import ThreeDot from "../../../assets/icons/threedot.svg";
import threedot_red from "../../../assets/icons/threedot_red.svg";

import {
  getSearchBusinessAsync,
  UpdateStatusAsync,
  selectedUserDetails,
  deleteBusinessAsync,
} from "../../../redux/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import NOdataFound from "../../../assets/icons/NoAdminData.svg";
import SearchBusinessAccDialog from "../../../components/Dialogs/AdminPanelActionDialog/SearchBusinessAccDialog";
import Footer from "../../../components/Footer/Footer";
import { Constants } from "../../../utils/Constants";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "react-switch";
// ---]
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import avatar from "../../../assets/icons/avatar.svg";
import { SelectedRoute } from "../../../redux/adminManagementSlice";

function SearchBusinessAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [CurrentPage, setCurrentPage] = useState(1);
  const {
    searchBussinessData,
    searchBussinessLoading,
    PaginationDataLength,
    startedRecords,
    toRecords,
    totalPages,
    deleteBusinessLoading,
    updateBusinessLoading,
  } = useSelector((state) => state.users);

  const [searchBussAccountDeleteData, setSearchBussAccountDeleteData] =
    useState(false);
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [page, setPage] = useState(1);
  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [lastName, setlastName] = useState("");
  const SelectedBusinessEmail = useRef(null);
  const [approvalStatus, setApprovalStatus] = useState({});
  //----- Filters
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("Business");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Search by");

  const handleSelection = (value) => {
    setFilter(value);
    setSelectedValue(value);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleClickOpen1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleChangeSelect = (event) => {
    setFilter(event.target.value || "");
    console.log("this is filter", filter);
  };
  useEffect(() => {
    const initialStatus = {};
    searchBussinessData.forEach((item) => {
      initialStatus[item.id] = item.isAdminApproved;
    });
    setApprovalStatus(initialStatus);
  }, [searchBussinessData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSelect = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  //-----
  const data = {
    location,
    email,
    address,
    isAdminApproved: 1,
    businessName,
    lastName,
    demo: 0,
    page: 1,
    perPage: Constants.TAKE,
  };
  const searchBusAccountHandleClose = () => {
    setSearchBussAccountDeleteData(false);
  };

  const handleChange = (event, value) => {
    data.page = value;
    dispatch(getSearchBusinessAsync(data));
  };

  useEffect(() => {
    dispatch(getSearchBusinessAsync(data));
  }, [dispatch]);

  // const deleteBusiness = (item) => {
  //   SelectedBusinessEmail.current = item.id;
  //   setSearchBussAccountDeleteData(true);
  // };
  const GoToChat = (item) => {
    dispatch(SelectedRoute("/Messages"));
    navigate("/Messages", {
      state: item,
    });
  };

  const SearchQuery = (e) => {
    const filterToStateMap = {
      Business: setBusinessName,
      Email: setEmail,
      Location: setLocation,
      Address: setAddress,
    };
    const setStateFunction = filterToStateMap[filter];
    if (setStateFunction) {
      setStateFunction(e.target.value);
      // Reset other state values
      for (const stateSetter of Object.values(filterToStateMap)) {
        if (stateSetter !== setStateFunction) {
          stateSetter(undefined);
        }
      }
    }
  };
  const getDataByQuery = () => {
    dispatch(getSearchBusinessAsync(data));
  };
  const NavigateToBusinessUserProfile = (item) => {
    dispatch(selectedUserDetails(item));
    navigate("/UserProfile/UserProfile");
  };
  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };

  // mark as Unverified

  const Unapproved = (id) => {
    const newStatus = !approvalStatus[id];
    setApprovalStatus((prevStatus) => ({
      ...prevStatus,
      [id]: newStatus,
    }));
    const changeStatus = {
      accountType: "business",
      id: id,
      isAdminApproved: false,
      isReview: true,
      PrevVeried: true,
    };
    dispatch(UpdateStatusAsync(changeStatus));
  };
  return (
    <Container>
      {searchBussinessLoading ||
      deleteBusinessLoading ||
      updateBusinessLoading ? (
        <Loader />
      ) : null}
      <div className="ContainerForSearchBussiness p-3 ">
        {searchBussinessData?.length === 0 && searchBussinessLoading ? (
          <div className="NoAdminDataForSearchBusAccount">
            <img src={NOdataFound} alt="NO DATA FOUND" />
            <div className="NoAdminDataForSearchBusAccount">
              <h1>No Business Users Available</h1>
              <h3>Currently there are no business users.</h3>
            </div>
          </div>
        ) : (
          <>
            <div className="HeadingAndSearchForBusiness">
              <h1>Search Business account</h1>
              <div className="RightBarForBusiness">
                {/* filter-buttons */}
                {/* <button className="locationByForBusiness">
                  Category
                  <img
                    style={{ marginLeft: 5 }}
                    src={keyboard_arrow_down}
                    alt="category"
                  />
                </button>
                <button className="locationByForBusiness">
                  Sub Category
                  <img
                    style={{ marginLeft: 5 }}
                    src={keyboard_arrow_down}
                    alt="  Sub Category"
                  />
                </button>
                <button className="locationByForBusiness">
                  Location Filter
                  <img
                    style={{ marginLeft: 5 }}
                    src={keyboard_arrow_down}
                    alt=" Location Filter"
                  />
                </button> */}
                <div className="btn_container">
                  <button
                    onClick={handleClickOpen1}
                    className="SearchByForBusiness"
                  >
                    {selectedValue}
                    <img
                      style={{ marginLeft: 5 }}
                      src={keyboard_arrow_down}
                      alt="Search by"
                    />
                  </button>
                  {isDropdownOpen && (
                    <div className="dropdown-content1">
                      <ul>
                        <li onClick={() => handleSelection("Business")}>
                          Business
                        </li>
                        <li onClick={() => handleSelection("Location")}>
                          Location
                        </li>
                        <li onClick={() => handleSelection("Email")}>Email</li>
                        <li onClick={() => handleSelection("Address")}>
                          Address
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                {/* <Dialog
                  disableEscapeKeyDown
                  open={open}
                  onClose={handleCloseSelect}
                >
                  <DialogContent>
                    <Box
                      component="form"
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel htmlFor="demo-dialog-native">
                          Filter By
                        </InputLabel>
                        <Select
                          native
                          value={filter}
                          onChange={handleChangeSelect}
                          input={
                            <OutlinedInput
                              label="Filter by"
                              id="demo-dialog-native"
                            />
                          }
                        >
                          <option value="">Select an option</option>
                          <option value="Business">Business</option>
                          <option value="Location">Location</option>
                          <option value="Email">Email </option>
                          <option value="Address">Address</option>
                        </Select>
                      </FormControl>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseSelect}>Cancel</Button>
                    <Button onClick={handleCloseSelect}>Ok</Button>
                  </DialogActions>
                </Dialog> */}
                <div className="inputDivForBusiness">
                  <SearchIcon
                    onClick={getDataByQuery}
                    className="searchIconForBusiness"
                    style={{ color: "#777", fontSize: 22 }}
                  />
                  <input
                    onChange={SearchQuery}
                    onKeyDown={(event) => {
                      event.key === "Enter" && getDataByQuery();
                    }}
                    type="text"
                    placeholder={`Search ${filter}`}
                  />
                </div>
              </div>
            </div>
            <TableContainer
              sx={{ height: "calc( 100% - 40px)", marginTop: "1rem" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {/* <Checkbox checked={checked1}
                              onChange={toggleSelectAll} /> */}
                        <p>Username</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Email Address</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Phone No</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Location</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>

                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Category</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Sub-Category</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Address</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Mark as Unverified</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Action</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchBussinessData?.map((item, i) => {
                    return (
                      <TableRow
                        className={classes.bodyroot}
                        hover
                        role="checkbox"
                      >
                        <TableCell key={i}>
                          <span
                            onClick={() => NavigateToBusinessUserProfile(item)}
                            className="flex justify-start items-center "
                          >
                            {/* <Checkbox
                                    checked={checkboxStates[item?.userId] || checked1}
                                    onChange={() => toggleCheckbox(item, i)} /> */}
                            <div
                              style={{
                                width: "2rem",
                                height: "2rem",
                                borderRadius: "50%",
                                border: "1px solid grey",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  borderRadius: "50%",
                                }}
                                src={
                                  item?.image &&
                                  (item.image.includes(".jpg") ||
                                    item.image.includes(".jpeg") ||
                                    item.image.includes(".png"))
                                    ? `https://${item?.image}`
                                    : avatar
                                }
                                alt="user"
                                onError={FailedToLoad}
                              />
                            </div>
                            <span className=" FirstClildSearchBusiness ">
                              <span
                                onClick={() =>
                                  NavigateToBusinessUserProfile(item)
                                }
                                className="underLineTextSearchBusiness "
                              >
                                {item?.userId}
                              </span>
                              <span className="returingDataSearchBusiness">
                              {item?.firstName}  {item?.lastName}
                              </span>
                            </span>
                          </span>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearchBusiness">
                            {item?.emailAddress}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearchBusiness">
                            {item?.phoneNumber}
                          </p>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            {item?.location?.map((data, i) => (
                              <span
                                key={i}
                                className="returingDataSearchBusiness"
                              >
                                {data}
                                {i < item.location.length - 1 && ", "}
                              </span>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearchBusiness">
                            {item?.category?.name}
                          </p>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "0.2rem",
                            }}
                          >
                            {item?.subCategory?.map((sub, i) => (
                              <p key={i} className="returingDataTempBusiness">
                                {sub?.name}
                                {i < item.subCategory.length - 1 ? "," : ""}
                              </p>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearchBusiness">
                            {item?.streetAddress}
                          </p>
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={approvalStatus[item.id] || false}
                            onChange={() => Unapproved(item.id)}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={22}
                            width={47}
                          />
                        </TableCell>
                        <TableCell>
                          <img
                            // onClick={() => deleteBusiness(item)}
                            onClick={() => GoToChat(item)}
                            style={{
                              marginLeft: "0.5rem",
                              cursor: "pointer",
                            }}
                            src={!item?.isLastMsgRead ? threedot_red : ThreeDot}
                            alt="More"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        <SearchBusinessAccDialog
          searchBussAccountDeleteData={searchBussAccountDeleteData}
          setSearchBussAccountDeleteData={setSearchBussAccountDeleteData}
          searchBusAccountHandleClose={searchBusAccountHandleClose}
          SelectedBusinessEmail={SelectedBusinessEmail}
        />
      </div>
      {PaginationDataLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={PaginationDataLength}
          startedRecords={startedRecords}
          toRecords={toRecords}
          totalPages={totalPages}
        />
      ) : null}
    </Container>
  );
}
export default SearchBusinessAccount;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      height: "28px",
      padding: "0.3rem 1rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 1rem",
    },
  },
});
