import React, { useState } from "react";
import "./UserProfile.scss";
import Container from "../../../components/HOC/Container.jsx";
import { useNavigate } from "react-router-dom";
import Arrow from "../../../assets/icons/arrow-left.svg";
import Cross from "../../../assets/icons/cross-blue.svg";
import Save from "../../../assets/icons/save.svg";
import verified from "../../../assets/icons/verified.svg";
import avatar from "../../../assets/icons/admin-pic-icon.svg";
import Chat from "../Messages/Chat.jsx";

import Switch from "react-switch";
import Home from "../../../assets/icons/home.svg";
import Call from "../../../assets/icons/Call.svg";
import mail from "../../../assets/icons/mail.svg";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ScrollToBottom from "react-scroll-to-bottom";
import DemoJobs_img from "../../../assets/icons/image_4.svg";
import Location from "../../../assets/icons/Map-pin.svg";
import ChatIcon from "../../../assets/icons/chat-bubble.svg";
import File from "../../../assets/icons/File.svg";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import {
  AskQuestionAsync,
  Store_Selected_Project,
  getAllQnAAsync,
  getAll_temp_ProjectAsync,
  get_all_temp_project_success,
  DummyRateAsync,
  GetAllDummyRateAsync,
} from "../../../redux/projectSlice.js";
import StarRatings from "react-star-ratings";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { UpdateProjectStatusAsync } from "../../../redux/categorySlice";
import { Tooltip } from "react-tooltip";
import Loader from "../../../components/Loader.js";
import Swal from "sweetalert2";
import avatar_img from "../../../assets/icons/avatar.svg";
import ThreeDot from "../../../assets/icons/threedot.svg";

import {
  getChatRoomsUser,
  getAllMessagesAsync,
  setNewlyCreatedQA,
} from "../../../redux/chatSlice.js";
import DraggableImage from "../../../components/Dialogs/ViewFiles/Docs/DraggableImage.jsx";
import { UpdateStatusAsync } from "../../../redux/usersSlice.js";
import cancelOTP from "../../../assets/icons/cancelOTP.svg";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import moment from "moment/moment.js";
import { GetAlltransactionsAsync } from "../../../redux/adSlice.js";
import { Constants } from "../../../utils/Constants.js";
import Footer from "../../../components/Footer/Footer";
import { SelectedRoute } from "../../../redux/adminManagementSlice.js";
const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails, newCustomerAccVerificationLoading } = useSelector(
    (state) => state.users
  );
  const {
    transactionData,
    totalTransactionPage,
    TransactionToRecords,
    TransactionStartedRecords,
    TransactionPaginationDataLength,
    getTransactionLoading,
  } = useSelector((state) => state.ad);
  const {
    temp_project_data,
    Selected_Project,
    temp_project_loading,
    QnAData,
    RateCommentLoading,
    GetAllRateCommentLoading,
    GetAllRateCommentData,
    updateProjectStatusLoading,
    updateBusinessLoading,
    totalQnAPage,
    QnAToRecords,
    QnAStartedRecords,
    QnADataLength,
  } = useSelector((state) => state.project);
  const { IsReceivedNewQA, ReceivedQAData } = useSelector(
    (state) => state.chat
  );
  const { updateProjectLoading } = useSelector((state) => state.category);
  const [value, setValue] = useState("1");
  const [fileOpen, setFileOpen] = useState(false);
  const [seletedtab, setSelectedtab] = useState(1);
  const [visible, setVisible] = useState(false);
  const [selectedQId, setSelectedQId] = useState("");
  const [answer, setAnswer] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [unVerified, setUnVerified] = useState(false);
  const [rateDialog, setRateDialog] = useState(false);
  const [rating, setRating] = useState(0);
  const [businessName, setBusinessName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [comment, setComment] = useState("");
  const classes = useStyles();
  const [LoggedInId, setLoggedInId] = useState([]);
  const [page, setPage] = useState(1);
  const { ChatRoomsUsers, messages } = useSelector((state) => state.chat);
  const CustomerReply = (item) => {
    setSelectedQId(item?.id);
    setVisible(true);
  };

  useEffect(() => {
    let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    setLoggedInId(loggesInUser);
  }, []);
  const handleAnsChange = (e) => {
    setAnswer(e.target.value);
  };
  const Submit = () => {
    const data = {
      questionId: selectedQId,
      customerId: Selected_Project?.customerId,
      answer: answer,
      accountType: "customer",
    };
    dispatch(AskQuestionAsync(data)).then((resp) => {
      if (resp?.status === 200 || 201) {
        setVisible(false);
        dispatch(getAllQnAAsync(Selected_Project?.id, Constants.TAKE, 1));
      }
    });
  };
  useEffect(() => {
    setFileOpen(false);
  }, []);

  const getData = (value) => {
    if (userDetails?.tempAccount === true || userDetails?.isAdminApproved) {
      let data = {
        CustomerId: userDetails?.id,
        BusinessId: userDetails?.id,
        page: 1,
        status: value,
        perPage: 20,
        accountType: userDetails?.accountType,
      };
      dispatch(getAll_temp_ProjectAsync(data));
    } else {
      dispatch(get_all_temp_project_success([]));
    }
  };
  const tData = {
    businessId: userDetails?.id,
    page: 1,
    perPage: Constants.TAKE,
  };
  const handleChangeTab = (event, newValue) => {
    console.log("HERE", newValue);
    setValue(newValue);
    setFileOpen(false);
    setSelectedtab(newValue);
    if (newValue == 2) {
      dispatch(getChatRoomsUser(LoggedInId?.id, userDetails?.id, "admin")).then(
        (res) => {
          console.log("##resssss", res);
          dispatch(
            getAllMessagesAsync(res?.id, Constants.takeForChat, 0, false)
          );
        }
      );

      if (userDetails?.accountType === "customer") {
        getData(0);
      }
      dispatch(getAllQnAAsync(userDetails?.id));
    } else if (newValue == 4) {
      getData(1);
    } else if (newValue == 5) {
      getData(4);
    } else if (newValue == 6) {
      getData(5);
    } else if (newValue == 7 && userDetails?.accountType === "business") {
      dispatch(GetAlltransactionsAsync(tData, Constants.TAKE, 1));
    }
  };
  const handleChange = (event, val) => {
    setPage(val);
    if (value == 2) {
      dispatch(getAllQnAAsync(Selected_Project?.id, Constants.TAKE, val));
    } else if (value == 7) {
      dispatch(GetAlltransactionsAsync(tData, Constants.TAKE, val));
    }
  };
  const Add_new_Project = () => {
    dispatch(Store_Selected_Project(null));
    navigate("/AddProject");
  };
  const Edit_project = (item) => {
    dispatch(Store_Selected_Project(item));
    navigate("/AddProject");
  };
  const Updated_project = (item) => {
    let data = {
      projectId: item?.id,
      status: 1,
      completedBy: userDetails?.id,
    };
    dispatch(UpdateProjectStatusAsync(data, navigate)).then((response) => {
      if (response === 200 || 201) {
        Swal.fire({
          icon: "success",
          text: "Project published",
        });
        if (value == 2) {
          getData(0);
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Project not published",
        });
      }
    });
  };

  // On broken image handling
  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar_img;
  };
  const NavigateToProjectpage = (item) => {
    dispatch(setNewlyCreatedQA(false));
    dispatch(Store_Selected_Project(item));
    navigate("/ProjectTabbar");
    dispatch(getAllQnAAsync(item?.id));
  };
  const Unapproved = () => {
    setUnVerified(true);
    const changeStatus = {
      accountType: userDetails?.accountType,
      id: userDetails?.id,
      isAdminApproved: userDetails?.isAdminApproved,
      isReview: true,
      PrevVeried: userDetails?.isAdminApproved ? true : false,
    };
    dispatch(UpdateStatusAsync(changeStatus)).then((r) => {
      if (r?.data?.success === true) {
        navigate("-1");
      }
    });
  };
  const RateComment = () => {
    setRateDialog(true);
  };
  const changeRating = (newRating) => {
    setRating(newRating);
  };
  const SubmitComment = () => {
    let data = {
      customerId: userDetails?.id,
      comment: comment,
      rating: rating,
      type: "dummy",
      businessName: businessName,
      dummyProjectName: projectName,
    };

    if (!businessName?.trim("")?.length > 0) {
      toast.error("Enter Business Name !!");
    } else if (!projectName?.trim("")?.length > 0) {
      toast.error("Enter Project Name !!");
    } else if (!comment?.trim("")?.length > 0) {
      toast.error("Write Your comment  !!");
    } else {
      dispatch(DummyRateAsync(data)).then((r) => {
        console.log("resp after submit ", r);
        if (r?.status === 200 || 201) {
          setBusinessName(null);
          setRating(0);
          setComment(null);
          setRateDialog(false);
        }
      });
    }
  };

  useEffect(() => {
    let data = {
      accountType: userDetails?.accountType,
      userId: userDetails?.id,
      page: 1,
      perPage: 20,
    };
    if (
      // userDetails?.accountType === "customer" &&
      userDetails?.tempAccount === true ||
      !userDetails?.demo
    ) {
      dispatch(GetAllDummyRateAsync(data));
    }
  }, []);
  const GoToChat = () => {
    dispatch(SelectedRoute("/Messages"));
    navigate("/Messages", {
      state: userDetails,
    });
  };
  const GoToCustomerPage = (item) => {
    if (item?.paymentType === "sale") {
      navigate("/ProjectTabbar", {
        state: item?.project,
      });
    }
  };

  const Charge = (data) => {
    let numericValue = parseFloat(data.replace(/[$,]/g, ""));
    let per = (numericValue * 10) / 100;
    return per;
  };
  const formatDate = (timestamp) => {
    const today = moment().startOf("day");
    const messageDate = moment(parseInt(timestamp, 10));

    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(today.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("DD/MM/YYYY");
    }
  };
  return (
    <Container>
      <div className="UserProfileMainContainer">
        {updateProjectLoading ||
        newCustomerAccVerificationLoading ||
        GetAllRateCommentLoading ||
        updateProjectStatusLoading ||
        updateBusinessLoading ||
        getTransactionLoading ? (
          <Loader />
        ) : null}
        <div className="UserProfileMainContainerHeader">
          <div onClick={() => navigate(-1)} className="HeadingAndBack">
            <button>
              <img src={Arrow} alt="Arrow" className="h-6" />
            </button>
            <h1>Back</h1>
          </div>
          <>
            {seletedtab == 1 && (
              <>
                {userDetails?.tempAccount === true && (
                  <div className="Close_btn_And_Edit_btn">
                    <button onClick={() => navigate(-1)} className="cancelBtn ">
                      <img src={Cross} alt="Cancel" />
                      <span>Close</span>
                    </button>
                    <button
                      onClick={() => navigate("/EditPage/EditUser")}
                      className="saveBtn "
                    >
                      <img src={Save} alt="Save" />
                      <span>Edit</span>
                    </button>
                  </div>
                )}
              </>
              // : (
              //   <view
              //     className="View_docs "
              //     onClick={() => setOpenDialog(true)}
              //   >
              //     <img src={Save} alt="Save" />
              //     <span>View Docs</span>
              //   </view>
              // )
            )}
          </>
        </div>

        <div className="UserProfile_Detail_Container">
          {openDialog && (
            <DraggableImage
              openDialog={openDialog}
              verificationData={userDetails?.ShowDocsUrl}
              setOpenDialog={setOpenDialog}
            />
          )}
          <Dialog onClose={() => setRateDialog(false)} open={rateDialog}>
            {RateCommentLoading && <Loader />}
            <div className="RateMainContainer">
              <h3>Rate and Comment</h3>
              <img
                className="Cross"
                src={cancelOTP}
                onClick={() => setRateDialog(false)}
                alt="cancel"
              />
              <div className="InpputContainer">
                <div className="Dual_Input">
                  <input
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    type="text"
                    placeholder="Enter Dummy business name"
                  />
                  <input
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    type="text"
                    placeholder="Enter Dummy Project name"
                  />
                </div>
                <div className="RatingContainer">
                  <span>Select rating</span>
                  <StarRatings
                    rating={rating}
                    starRatedColor="green"
                    starEmptyColor="grey"
                    starDimension="1.3rem"
                    changeRating={changeRating}
                    numberOfStars={5}
                    name="rating"
                  />
                </div>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  style={{ height: "130px" }}
                  type="text"
                  placeholder="Write your comment here "
                />
              </div>

              <div onClick={SubmitComment} className="SubmitBTN">
                Submit
              </div>
            </div>
          </Dialog>

          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box
                sx={{
                  marginLeft: "1rem",
                }}
              >
                <TabList onChange={handleChangeTab}>
                  <Tab
                    sx={{
                      "&.Mui-selected": {
                        outline: "none",
                        color: "#1C8C5E",
                        borderColor: "#1C8C5E",
                      },
                    }}
                    label="User Account Details"
                    value="1"
                  />
                  {userDetails?.tempAccount === true ? (
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          outline: "none",
                          color: "#1C8C5E",
                          borderColor: "#1C8C5E",
                        },
                      }}
                      label="Posted Jobs"
                      value="2"
                    />
                  ) : null}
                  {!userDetails?.demo && !userDetails?.tempAccount ? (
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          outline: "none",
                          color: "#1C8C5E",
                          borderColor: "#1C8C5E",
                        },
                      }}
                      label="QnA"
                      value="2"
                    />
                  ) : null}
                  {(userDetails?.accountType === "customer" &&
                    ((userDetails?.tempAccount &&
                      userDetails?.isAdminApproved) ||
                      (userDetails?.isAdminApproved &&
                        !userDetails?.tempAccount))) ||
                  (userDetails?.isAdminApproved && !userDetails?.demo) ? (
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          outline: "none",
                          color: "#1C8C5E",
                          borderColor: "#1C8C5E",
                        },
                      }}
                      label="Rate and Comments"
                      value="3"
                    />
                  ) : null}
                  {(userDetails?.accountType === "customer" &&
                    userDetails?.isAdminApproved &&
                    userDetails?.tempAccount === false) ||
                  (userDetails?.accountType === "business" &&
                    userDetails?.isAdminApproved &&
                    userDetails?.demo === false) ? (
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          outline: "none",
                          color: "#1C8C5E",
                          borderColor: "#1C8C5E",
                        },
                      }}
                      label="Projects in bidding"
                      value="4"
                    />
                  ) : null}
                  {(userDetails?.accountType === "customer" &&
                    userDetails?.isAdminApproved &&
                    userDetails?.tempAccount === false) ||
                  (userDetails?.accountType === "business" &&
                    userDetails?.isAdminApproved &&
                    userDetails?.demo === false) ? (
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          outline: "none",
                          color: "#1C8C5E",
                          borderColor: "#1C8C5E",
                        },
                      }}
                      label="Projects in progress"
                      value="5"
                    />
                  ) : null}
                  {(userDetails?.accountType === "customer" &&
                    userDetails?.isAdminApproved &&
                    userDetails?.tempAccount === false) ||
                  (userDetails?.accountType === "business" &&
                    userDetails?.isAdminApproved &&
                    userDetails?.demo === false) ? (
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          outline: "none",
                          color: "#1C8C5E",
                          borderColor: "#1C8C5E",
                        },
                      }}
                      label="Project history"
                      value="6"
                    />
                  ) : null}
                  {userDetails?.accountType === "business" &&
                    !userDetails?.demo && (
                      <Tab
                        sx={{
                          "&.Mui-selected": {
                            outline: "none",
                            color: "#1C8C5E",
                            borderColor: "#1C8C5E",
                          },
                        }}
                        label="Invoices/Payments history "
                        value="7"
                      />
                    )}
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="UserDetail_container">
                  <div className="UserProfile_Deatils">
                    <div className="Avtar_Conatiner">
                      <div className="avatar">
                        <img
                          style={{
                            height: "5rem",
                            width: "5rem",
                            borderRadius: "80%",
                            border: "1px solid grey",
                          }}
                          src={
                            userDetails?.image
                              ? `https://${userDetails?.image}`
                              : avatar_img
                          }
                          alt="user"
                          onError={FailedToLoad}
                        />
                        <img
                          className="Verified"
                          src={verified}
                          alt="Varified User"
                        />
                      </div>
                      {/* <h1>{userDetails.fullName}</h1>
                      <h6 style={{ textAlign: "center" }}>#03-2022</h6> */}

                      {userDetails?.accountType === "customer" &&
                      userDetails?.tempAccount === true ? (
                        <div onClick={Add_new_Project} className="AddProject">
                          <h1>Add Project</h1>
                        </div>
                      ) : null}
                    </div>
                    <div className="Addresss_container">
                      <div className="Address">
                        <div className="BusinessAddress">
                          <img src={Home} alt="Home" />
                          <div className="business_address">
                            <h5>Business Address</h5>
                            <h2>{userDetails?.streetAddress}</h2>
                          </div>
                          {userDetails?.tempAccount === true ? (
                            <div className="IsVerified">
                              <span>Verified</span>
                            </div>
                          ) : (
                            <div className="IsVerified">
                              {userDetails?.isVerified === true ? (
                                <span>Verified</span>
                              ) : (
                                <span>Not Verified</span>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="BusinessAddress">
                          <img src={Call} alt="Home" />
                          <div className="business_address">
                            <h5>Contact No.</h5>
                            <h2>{userDetails.phoneNumber}</h2>
                          </div>
                          {userDetails?.tempAccount === true ? (
                            <div className="IsVerified">
                              <span>Verified</span>
                            </div>
                          ) : (
                            <div className="IsVerified">
                              {userDetails?.isVerified === true ? (
                                <span>Verified</span>
                              ) : (
                                <span>Not Verified</span>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="BusinessAddress">
                          <img src={mail} alt="Home" />
                          <div className="business_address">
                            <h5>Email Address</h5>
                            <h2>{userDetails?.emailAddress}</h2>
                          </div>
                          {userDetails?.tempAccount === true ? (
                            <div className="IsVerified">
                              <span>Verified</span>
                            </div>
                          ) : (
                            <div className="IsVerified">
                              {userDetails?.isVerified === true ? (
                                <span>Verified</span>
                              ) : (
                                <span>Not Verified</span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <h1 className="Discription_title">Business Discription</h1>
                    <h4>
                      I am offering to my client an attractive screenshot that
                      can be used for the google play sote. App store window
                      store and on your website. The screenshot helps to
                      increase tyour amketing in between people.
                      <br /> I can also design a cataog of your softwate by
                      usein ghe same screensh
                    </h4>
                  </div>
                  <div className="Full_Detail">
                    <div className="Personal_Detail">
                      <div className="NameContainer">
                        <h1>
                          {userDetails?.fullName
                            ? userDetails?.fullName
                            : "Temp User"}{" "}
                        </h1>
                        <div className="LocationContainer">
                          <img src={Location} />
                          <p>{userDetails?.location}</p>
                        </div>
                      </div>
                      {userDetails?.accountType !== "customer" && (
                        <div className="RoleContainer">
                          <span>Role :</span>{" "}
                          <p className="UserRole">{userDetails?.Position}</p>
                        </div>
                      )}
                      <div className="Rating_Container">
                        <h4>Rating</h4>
                        <StarRatings
                          rating={
                            userDetails?.Avg_Rating !== null
                              ? userDetails?.Avg_Rating
                              : 4
                          }
                          // rating={4}
                          starRatedColor="#38ab7c"
                          numberOfStars={5}
                          name="rating"
                          starDimension="20px"
                          starSpacing="1px"
                          starEmptyColor="grey"
                        />
                      </div>
                      <div className="ChatContainer">
                        {!userDetails?.tempAccount && !userDetails?.demo && (
                          <div className="ChatContainer">
                            <div onClick={GoToChat} className="Chat">
                              <img src={ChatIcon} alt="Chat" />
                              <span>Chat</span>
                            </div>
                          </div>
                        )}

                        {
                          // !userDetails?.tempAccount &&
                          //   !userDetails?.demo &&
                          userDetails?.accountType === "customer" &&
                          !userDetails?.tempAccount &&
                          userDetails?.isAdminApproved ? (
                            <div className="UnVerified">
                              <p>Mark as Unverified</p>
                              <Switch
                                onChange={Unapproved}
                                checked={unVerified}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={22}
                                width={47}
                              />
                            </div>
                          ) : (
                            <>
                              {userDetails?.accountType === "customer" &&
                              userDetails?.tempAccount ? (
                                <div
                                  onClick={RateComment}
                                  className="RateNComment"
                                >
                                  <p>Rate/Comments</p>
                                </div>
                              ) : null}
                            </>
                          )
                        }
                      </div>
                    </div>
                    <div className="About_Container">
                      <div className="About">
                        <h5>ABOUT</h5>
                      </div>
                      <p className="Header">Contact Information</p>
                      <div className="Common_Details">
                        <h6 className="Common_headers">Phone :</h6>
                        <p className="Common_Data">
                          {userDetails?.phoneNumber}
                        </p>
                      </div>
                      <div className="Common_Details">
                        <h6 className="Common_headers">Address :</h6>
                        <p className="Common_Data">
                          {userDetails?.streetAddress} , {userDetails?.city} ,{" "}
                          {userDetails?.state}
                        </p>
                      </div>
                      <div className="Common_Details">
                        <h6 className="Common_headers">Email :</h6>
                        <p className="Common_Data">
                          {userDetails?.emailAddress}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              {fileOpen === false &&
              userDetails?.accountType === "customer" &&
              userDetails?.tempAccount === true ? (
                <TabPanel className="Customer_Completed_jobs" value="2">
                  {temp_project_data?.length > 0 && (
                    <h2>Recently Posted jobs </h2>
                  )}
                  <div className="Project_listed_container">
                    <div className="ProjectWrapper">
                      {temp_project_loading === true ? <Loader /> : null}
                      {temp_project_data?.length > 0 ? (
                        temp_project_data?.map((item) => {
                          return (
                            <div
                              style={{
                                border:
                                  (IsReceivedNewQA &&
                                    ReceivedQAData?.projectId === item?.id) ||
                                  (item?.userQATbls &&
                                    item.userQATbls?.some(
                                      (qa) => qa?.answer === null
                                    ))
                                    ? "1px solid red"
                                    : "1px dotted #616b7a",
                              }}
                              className="Project_main_container"
                              key={item.id}
                            >
                              <div className="Project_Container">
                                <div
                                  onClick={() => NavigateToProjectpage(item)}
                                  className="NameAndStar_Container"
                                >
                                  <h1>
                                    Project name :{" "}
                                    <span
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={
                                        item?.projectTitle?.length > 10
                                          ? item?.projectTitle
                                          : null
                                      }
                                    >
                                      {item?.projectTitle?.length > 10
                                        ? `${item?.projectTitle?.slice(
                                            0,
                                            10
                                          )} ...`
                                        : item?.projectTitle}
                                    </span>
                                  </h1>
                                  <div className="Location_continer">
                                    Location :{" "}
                                    {item?.location
                                      ?.slice(0, 3)
                                      .map((loc, index) => (
                                        <h6 className="locations" key={index}>
                                          {loc}
                                          {index !== 2 && ", "}
                                        </h6>
                                      ))}
                                    {item?.location?.length > 3 && (
                                      <h6 className="locations">
                                        + {item.location.length - 3} more
                                        location
                                      </h6>
                                    )}
                                  </div>

                                  <div className="SubCAtegoryContainer">
                                    <h5>Category: {item?.category?.name}</h5>
                                  </div>
                                  <div className="descriptions">
                                    Description:
                                    {item?.jobDescription ? (
                                      <h6 style={{ paddingTop: "0.1rem" }}>
                                        {item?.jobDescription?.length > 20
                                          ? `${item?.jobDescription?.slice(
                                              0,
                                              20
                                            )} ...`
                                          : item?.jobDescription}
                                      </h6>
                                    ) : (
                                      <h6 style={{ paddingTop: "0.1rem" }}>
                                        No description mention
                                      </h6>
                                    )}
                                  </div>
                                </div>
                                <div className="fileAndPrice_Container">
                                  <div className="StatusContainer">
                                    {item?.projectStatus === 0 ? (
                                      <div className="Edit_and_Update">
                                        <DriveFileRenameOutlineOutlinedIcon
                                          onClick={() => Edit_project(item)}
                                          className="editIcon"
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content="Edit"
                                        />
                                        <div
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content="Move to publish"
                                        >
                                          <Switch
                                            onChange={() =>
                                              Updated_project(item)
                                            }
                                            checked={item.isAdminApproved}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={22}
                                            width={47}
                                            className="switch_button"
                                          />
                                          <Tooltip
                                            id="my-tooltip"
                                            style={{
                                              backgroundColor: "#38ab7c",
                                              zIndex: 100,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="current_tag">
                                      {item?.projectStatus === 0 ? (
                                        <p className="Draft_tag">Drafted</p>
                                      ) : item?.projectStatus === 4 ? (
                                        <p className="Working_tag">
                                          At working
                                        </p>
                                      ) : item?.projectStatus === 1 ? (
                                        <p className="Bidding_tag">
                                          In Bidding
                                        </p>
                                      ) : (
                                        <p className="Completed_tag">
                                          Completed
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="Stars_for_CompletedJobs">
                                    <StarRatings
                                      rating={
                                        item?.customer?.Avg_Rating !== null
                                          ? item?.customer?.Avg_Rating
                                          : 0
                                      }
                                      starRatedColor="#FFD555"
                                      numberOfStars={5}
                                      name="rating"
                                      starDimension="18px"
                                      starSpacing="1px"
                                      starEmptyColor="grey"
                                    />
                                    <div
                                      onClick={() =>
                                        NavigateToProjectpage(item)
                                      }
                                      className="Files_Icon"
                                    >
                                      <span>Files</span>
                                      <img src={File} alt="file" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="Empty_Text_Container">
                          <p className="Empty_Text">Project not Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>
              ) : (
                <>
                  {!userDetails?.demo && (
                    <TabPanel value="2">
                      <ScrollToBottom className="Chat_container">
                        <div className="qna-container">
                          {userDetails?.tempAccount ? (
                            <>
                              <h2>Frequently Asked Questions:</h2>
                              {QnAData?.map((item, index) => (
                                <div key={index} className="qna-item">
                                  <div className="question-container">
                                    <p className="question">
                                      Q : {item.question}
                                    </p>
                                    {item?.answer !== null ? (
                                      <div className="answer-container">
                                        <p className="answer">
                                          A : {item?.answer}
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="waiting-response">
                                        <button
                                          className="reply-button"
                                          onClick={() => CustomerReply(item)}
                                        >
                                          Answer the Question
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                              {visible && (
                                <div className="dialog">
                                  <p>Submit your response</p>
                                  <textarea
                                    className="textarea"
                                    value={answer}
                                    onChange={handleAnsChange}
                                    placeholder={"Reply to this question..."}
                                  />
                                  <button
                                    className="close-button"
                                    onClick={() => setVisible(false)}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="post-button"
                                    onClick={
                                      answer?.trim("") !== "" ? Submit : null
                                    }
                                  >
                                    Post
                                  </button>
                                </div>
                              )}
                            </>
                          ) : (
                            <Chat
                              messages={messages
                                .filter((m) => typeof m === "object")
                                .flat()
                                .reverse()}
                              currentUser={LoggedInId}
                              receiver={ChatRoomsUsers}
                              currentPage={1}
                              isQA={true}
                            />
                          )}
                        </div>
                      </ScrollToBottom>
                    </TabPanel>
                  )}
                </>
              )}
              {fileOpen === true &&
              userDetails?.accountType === "customer" &&
              userDetails?.tempAccount === true ? (
                <TabPanel className="Completed_jobs_detail" value="2">
                  <h1 onClick={() => setFileOpen(false)}>Back</h1>
                  <div className="Images_demo_container">
                    <div className="Image_demo">
                      <img src={DemoJobs_img} alt="Demo" />
                      <h3>File name.pdf</h3>
                    </div>
                    <div className="Image_demo">
                      <img src={DemoJobs_img} alt="Demo" />
                      <h3>File name.pdf</h3>
                    </div>
                    <div className="Image_demo">
                      <img src={DemoJobs_img} alt="Demo" />
                      <h3>File name.pdf</h3>
                    </div>
                    <div className="Image_demo">
                      <img src={DemoJobs_img} alt="Demo" />
                      <h3>File name.pdf</h3>
                    </div>
                    <div className="Image_demo">
                      <img src={DemoJobs_img} alt="Demo" />
                      <h3>File name.pdf</h3>
                    </div>
                    <div className="Image_demo">
                      <img src={DemoJobs_img} alt="Demo" />
                      <h3>File name.pdf</h3>
                    </div>
                  </div>
                </TabPanel>
              ) : null}
              {userDetails?.isAdminApproved && (
                <TabPanel className="CommentMainContainer" value="3">
                  <h3>Rate & Comments</h3>
                  <div className="RateAndCommentMainContainer">
                    {GetAllRateCommentData?.length > 0 ? (
                      <>
                        {GetAllRateCommentData?.map((item, i) => (
                          <>
                            {item?.buisnessUserFeedback !== null && (
                              <>
                                {item?.type === "dummy" ? (
                                  <div
                                    key={i}
                                    className="RateAndCommentContainer"
                                  >
                                    <div className="HeaderContainer">
                                      <div className="NameAndrating">
                                        <img src={avatar} alt="Avatar" />
                                        <p>{item?.businessName}</p>
                                        <StarRatings
                                          rating={item?.buisnessUserRating}
                                          starRatedColor="orange"
                                          starEmptyColor="grey"
                                          starDimension="1.1rem"
                                          numberOfStars={5}
                                          name="rating"
                                          starSpacing="0px"
                                        />
                                        <span className="CreatedTime">
                                          Posted at :{" "}
                                          {formatDate(item?.createdAt * 1000)}
                                        </span>
                                      </div>
                                      {item?.dummyProjectName && (
                                        <p className="ProjectTitle">
                                          {item?.dummyProjectName}
                                        </p>
                                      )}
                                    </div>
                                    <p className="paragraph">
                                      {item?.buisnessUserFeedback}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    key={i}
                                    className="RateAndCommentContainer"
                                  >
                                    <div className="HeaderContainer">
                                      <div className="NameAndrating">
                                        <img src={avatar} alt="Avatar" />

                                        <p>
                                          {userDetails?.accountType ===
                                          "customer"
                                            ? item?.businessUserTbl?.fullName
                                            : item?.customerTbl?.fullName}
                                        </p>
                                        <StarRatings
                                          rating={
                                            userDetails?.accountType ===
                                            "customer"
                                              ? item?.buisnessUserRating
                                                ? item?.buisnessUserRating
                                                : 0
                                              : item?.customerRating
                                              ? item?.customerRating
                                              : 0
                                          }
                                          starRatedColor="orange"
                                          starEmptyColor="grey"
                                          starDimension="1.1rem"
                                          numberOfStars={5}
                                          name="rating"
                                          starSpacing="0px"
                                        />
                                        <span className="CreatedTime">
                                          Posted at :{" "}
                                          {formatDate(item?.createdAt * 1000)}
                                        </span>
                                      </div>
                                      {item?.dummyProjectName && (
                                        <p className="ProjectTitle">
                                          {item?.project?.projectTitle}
                                        </p>
                                      )}
                                    </div>
                                    <p className="paragraph">
                                      {item?.buisnessUserFeedback}
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <div className="Empty_Text_Container">
                        <p className="Empty_Text">No Data Found</p>
                      </div>
                    )}
                  </div>
                </TabPanel>
              )}
              {(!userDetails?.demo || !userDetails?.tempAccount) &&
                userDetails?.isAdminApproved && (
                  <TabPanel
                    className="ProjectMainContainer"
                    value={
                      value === "4" || value === "5" || value === "6"
                        ? value
                        : null
                    }
                  >
                    <div className="ProjectWrapper">
                      {temp_project_loading && <Loader />}
                      {temp_project_data?.length > 0 ? (
                        <>
                          {temp_project_data?.map((item, i) => (
                            <div key={i} className="Project_main_container">
                              <div className="HeaderContainer">
                                <div className="ProjectNameContainer">
                                  <h2>Project Name : </h2>{" "}
                                  <h4 style={{ paddingTop: "0.15rem" }}>
                                    {item?.projectTitle}
                                  </h4>
                                </div>
                                {item?.projectStatus === 1 ? (
                                  <div className="status">In Bidding</div>
                                ) : item?.projectStatus === 4 ? (
                                  <div className="status">In progress</div>
                                ) : (
                                  <div className="status">Completed</div>
                                )}
                              </div>
                              <div className="ProjectNameContainer">
                                <h2>Location : </h2>{" "}
                                <h4 style={{ paddingTop: "0.15rem" }}>
                                  {item?.location}
                                </h4>
                              </div>
                              <div className="ProjectNameContainer">
                                <h2>Category Name : </h2>{" "}
                                <h4 style={{ paddingTop: "0.15rem" }}>
                                  {item?.category?.name}
                                </h4>{" "}
                                -{" "}
                                <div className="SubCat">
                                  <h3>Sub-cat : </h3>
                                  <h4>{item?.subCategoryTbl?.name}</h4>
                                </div>
                              </div>
                              <div className="ProjectNameContainer">
                                <h2>Description : </h2>
                                {item?.jobDescription ? (
                                  <h6 style={{ paddingTop: "0.1rem" }}>
                                    {item?.jobDescription?.split(" ").length > 5
                                      ? `${item?.jobDescription
                                          ?.split(" ")
                                          ?.slice(0, 5)
                                          ?.join(" ")} ...`
                                      : item?.jobDescription}
                                  </h6>
                                ) : (
                                  <h6 style={{ paddingTop: "0.1rem" }}>
                                    No description mention
                                  </h6>
                                )}
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div style={{ display: "flex", margin: "0 auto" }}>
                          <h2
                            style={{
                              paddingTop: "35vh",
                              fontSize: "2rem",
                              fontWeight: "600",
                              color: "#26405e",
                            }}
                          >
                            No Project found
                          </h2>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                )}
              {userDetails?.accountType === "business" &&
                !userDetails?.demo && (
                  <TabPanel value="7">
                    <div className="transaction-history-container">
                      <TableContainer sx={{ maxHeight: "78%" }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow className={classes.root}>
                              <TableCell>
                                <p className="Header_banking">Transaction ID</p>
                              </TableCell>
                              <TableCell>
                                <p className="Header_banking">Payment Date </p>
                              </TableCell>
                              <TableCell>
                                <p className="Header_banking">
                                  Project/Subcription
                                </p>
                              </TableCell>
                              <TableCell>
                                <p className="Header_banking">
                                  Payment gateway
                                </p>
                              </TableCell>
                              <TableCell>
                                <p className="Header_banking">
                                  Platform Charge (10%)
                                </p>
                              </TableCell>
                              <TableCell>
                                <p className="Header_banking">Total Amount</p>
                              </TableCell>
                              <TableCell>
                                <p className="Header_banking">Detail</p>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {transactionData?.map((item, i) => {
                              return (
                                <TableRow
                                  className={
                                    item?.paymentType === "add_subscription" ||
                                    item?.intent === "SUBSCRIPTION"
                                      ? classes.SubscriptionSell
                                      : classes.bodyroot
                                  }
                                  hover
                                  role="checkbox"
                                  key={i}
                                >
                                  <TableCell
                                    className={classes.customTableCell}
                                  >
                                    {item?.payment_Id || item?.paymentId
                                      ? `# ${
                                          item?.payment_Id || item?.paymentId
                                        }`
                                      : `#Not added`}
                                  </TableCell>
                                  <TableCell
                                    className={classes.customTableCell}
                                  >
                                    {formatDate(item?.createdAt * 1000)}
                                  </TableCell>
                                  <TableCell
                                    className={classes.customTableCell}
                                  >
                                    {item?.intent === "sale" ||
                                    item?.paymentType === "sale"
                                      ? item?.projectTitle ||
                                        item?.project?.projectTitle
                                      : "SUBSCRIPTION"}
                                  </TableCell>
                                  <TableCell
                                    className={classes.customTableCell}
                                  >
                                    {item?.paymentGateway ===
                                    "Merchant Payment Gateway"
                                      ? "Merchant"
                                      : "Paypal"}
                                  </TableCell>
                                  <TableCell
                                    className={classes.customTableCell}
                                  >
                                    {item?.totalAmount
                                      ? `${Charge(
                                          `$${item?.totalAmount}`
                                        ).toFixed(2)}`
                                      : item?.Amount
                                      ? `${Charge(`$${item?.Amount}`).toFixed(
                                          2
                                        )}`
                                      : "$0.00"}
                                  </TableCell>
                                  <TableCell
                                    className={classes.customTableCell}
                                  >
                                    $
                                    {item?.totalAmount
                                      ? item?.totalAmount
                                      : item?.Amount
                                      ? item?.Amount
                                      : "90"}
                                  </TableCell>
                                  <TableCell>
                                    <img
                                      onClick={() => GoToCustomerPage(item)}
                                      style={{
                                        marginLeft: "0.5rem",
                                        cursor: "pointer",
                                      }}
                                      src={ThreeDot}
                                      alt="More"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </TabPanel>
                )}
            </TabContext>
          </Box>
        </div>
      </div>
      {value == 7 && TransactionPaginationDataLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={TransactionPaginationDataLength}
          startedRecords={TransactionStartedRecords}
          toRecords={TransactionToRecords}
          totalPages={totalTransactionPage}
        />
      ) : null}

      {value == 2 && QnADataLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={QnADataLength}
          startedRecords={QnAStartedRecords}
          toRecords={QnAToRecords}
          totalPages={totalQnAPage}
        />
      ) : null}
    </Container>
  );
};

export default UserProfile;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      height: "28px",
      padding: "0.6rem 1rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.6rem 1rem",
    },
  },
  customTableCell: {
    color: "#616B7A !important",
    fontSize: "0.85rem !important",
    fontFamily: "roboto !important",
  },
  SubscriptionSell: {
    backgroundColor: "#b6dfca !important",
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      padding: "0.6rem 1rem",
    },
  },
});
