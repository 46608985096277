import React, { useEffect } from "react"; // useEffect
import { BrowserRouter as Router } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import AdminRoutes from "./AdminRoutes";
import MasterAdminRoutes from "./MasterAdminRoutes";
import { useSelector } from "react-redux";
import { logoutUserAsync } from "../redux/authSlice";
import { useDispatch } from "react-redux";

export default function Routes() {
  const dispatch = useDispatch();
  const { userType, userDetails } = useSelector((state) => state.auth);
  // useEffect(() => {
  // if (window.performance) {
  //   console.info("window performance work on browser");
  // }
  // if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
  //   console.info(
  //     "This page is reloaded",
  //     performance.navigation.TYPE_RELOAD,
  //     PerformanceNavigationTiming
  //   );
  // } else {
  //   if (!userDetails.remember) {
  //     localStorage.clear();
  //     console.log("cleared storage");
  //     dispatch(emptyAuthState());
  //   }
  //   console.info("This page is not reloaded or just called with empty cache");
  // }
  // }, []);
  // Code For Remember Me Functionality

  useEffect(() => {
    const handleLogout = async () => {
      if (userDetails?.id) {
        dispatch(logoutUserAsync(userDetails?.id));
      }
    };

    const handleBeforeUnload = (event) => {
      handleLogout();
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const renderRoutes = () => {
    switch (userType) {
      case "UNREGISTERED":
        return <AuthRoutes />;
      case "admin":
        return <AdminRoutes />;
      case "masteradmin":
        return <MasterAdminRoutes />;
      default:
        break;
    }
  };
  return <Router>{renderRoutes()} </Router>;
}
